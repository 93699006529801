import { useRouter } from "next/router";

const sendTrackingEvent = (params) => {
  if (typeof clevertap === "undefined") return;
  if (typeof dataLayer === "undefined") return;
  try {
    dataLayer.push(params);
  } catch (error) {
    // tracking error
  }
};

const signupTracking = (origin, job, company) => {
  const router = useRouter();
  const url = router.asPath;

  let signupMedium = "internal";
  let signupSource = "";

  if (url.includes("/companies")) {
    signupSource = "companies";
  } else if (url.includes("/jobs")) {
    signupSource = "jobs_list_search";
  } else if (url.includes("/")) {
    signupSource = "homepage";
  }

  if (Object.keys(company).length > 0) {
    signupSource = company.category ? `cp_${company.category}` : signupSource;
  }

  if (Object.keys(job).length > 0) {
    signupSource = job.category ? `ijp_${job.category}` : signupSource;
  }

  if (origin === "job_recommendation") {
    signupSource = "jobs_list_fyp";
  }

  return { signupMedium, signupSource };
};

export { sendTrackingEvent, signupTracking };
